<template>
  <div class="products-csv-modal fixed top-0 left-0 w-full h-full opacity-0">
    <div class="products-csv-modal-card relative bg-white p-12 ml-auto h-screen">
      <div class="flex flex-col text-black">
        <h3 class="text-lg opacity-70 font-light mb-6">Importar base de produtos</h3>

        <div class="modal-table-row flex items-start border-salmon border-opacity-30 py-5 w-full">
          <div class="modal-table-row__title flex-shrink-0 text-opacity-70 font-light">Importar/atualizar</div>

          <div class="text-black relative">
            <label for="import-file-input" class="import-file-button flex items-center justify-center cursor-pointer text-blue text-opacity-70 text-sm">
              Selecione um arquivo CSV
            </label>

            <span class="import-file__name text-sm text-black opacity-50 truncate overflow-ellipsis overflow-hidden w-full">
              {{ fileName }}
            </span>

            <input
              type="file"
              class="hidden"
              id="import-file-input"
              ref="file"
              name="import-file-input"
              accept=".csv"
              @change="onFileChange"
            >
          </div>
        </div>

        <div class="modal-table-row flex items-start border-salmon border-opacity-30 mt-4 py-5 w-full">
          <div class="modal-table-row__title flex-shrink-0 text-opacity-70 font-light"></div>

          <div class="text-black">
            <Button @click="importProducts" expanded :loading="loading" :disabled="!fileName">Confirmar</Button>
          </div>
        </div>
      </div>
    </div>

    <span v-if="hasCSVModal" class="products-csv-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer" @click="closeCSVModal" />
  </div>
</template>

<script>
import api from '@/utils/api.js';
import { mapMutations, mapState } from 'vuex';
import Button from '@/components/Button';
import dayjs from 'dayjs';

export default {
  name: "ProductsCSVModal",
  components: {
    Button,
  },
  props: ['item'],
  computed: {
    ...mapState(['hasCSVModal']),
  },
  data() {
    return {
      fileName: '',
      loading: false
    }
  },
  methods: {
    ...mapMutations(['closeCSVModal']),

    async importProducts() {
      const formData = new FormData();
      const files = this.$refs.file.files;

      for (let file of files) {
        formData.append(`file`, file);
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      this.loading = true;

      try {
        await api.post( 'import/products', formData, config);
        this.$noty.success('Base de produtos atualizado com sucesso.');
        this.closeCSVModal();
        this.fileName = '';
      } catch (e) {
        this.$noty.error('Ocorreu um erro ao tentar importar os produtos.');
      }

      this.loading = false;
    },
    getDate(date) {
      return dayjs(date);
    },
    onFileChange(event) {
      this.fileName = event.target.files[0].name;
    }
  }
};
</script>

<style lang="scss">
.has-csv-modal {
  overflow: hidden;

  .products-csv-modal {
    @apply opacity-100;
  }

  .products-csv-modal-layer {
    @apply opacity-30 pointer-events-auto;
  }

  .products-csv-modal-card {
    @apply transform-none pointer-events-auto;
  }
}

.products-csv-modal {
  z-index: 1;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.products-csv-modal-card {
  z-index: 1;
  width: 560px;
  box-shadow: -2px 0px 30px rgba(0, 0, 0, 0.08);
  transform: translateX(560px);
  transition: transform 0.3s;
  overflow-y: scroll;
}

.products-csv-modal-layer {
  z-index: -1;
}

.products-csv-modal {
  .modal-table-row {
    border-bottom: 1px solid rgba(246, 155, 139, 0.25);

    &:nth-child(2) {
      border-top: 1px solid rgba(246, 155, 139, 0.25);
    }

    &:nth-child(4),
    &:last-child {
      border-bottom: 0;
    }
  }
}

.modal-table-row__title {
  width: 160px;
  margin-right: 50px;
}

.import-file-button {
  width: 256px;
  height: 32px;

  border: 1px solid #5167CF;
  border-radius: 4px;
}

.import-file__name {
  position: absolute;
  top: 110%;
  left: 0;
}
</style>
