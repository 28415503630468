<template>
  <main class="products admin-page bg-cream p-12 ml-auto">
    <div class="container">
      <header class="admin-page-header flex items-center mb-10">
        <h1 class="text-3xl text-blue">Produtos</h1>
        <Button class="text-sm ml-4 focus:outline-none products-csv-button focus:outline-none" small @click="openCSVModal">
          <svg width="17" height="18" class="mr-1" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.645 0L0 3.99h3.484V11h2.322V3.99H9.29L4.645 0zm8.13 14.01V7h-2.323v7.01H6.968L11.613 18l4.645-3.99h-3.484z" fill="#fff"/><path d="M3.734 3.99v-.25H.674L4.646.33l3.97 3.41H5.557v7.01H3.734V3.99zm8.79 10.02v.25h3.06l-3.971 3.41-3.97-3.41h3.059V7.25h1.822v6.76z" stroke="#00136F" stroke-opacity=".5" stroke-width=".5"/></svg>

          CSV
        </Button>
        <search v-model="search" />
      </header>

      <Loader :isloading="loading" />

      <ul v-if="!loading" class="products-list grid-cols-2 md:grid-cols-3 xl:grid-cols-5 grid pb-16">
        <product v-for="product in items" :key="product.id" :product="product" show-status />
      </ul>

      <pagination :paginator="paginator" @load="loadItems" />
    </div>

    <ModalProductsCSV />
  </main>
</template>

<script>
import { mapMutations } from 'vuex';

import Search from '@/components/admin/Search';
import Button from '@/components/Button';
import Product from '@/components/Product';
import Loader from '@/components/Loader';
import Pagination from '@/components/Pagination';
import paginator from '@/utils/paginator.js';
import ModalProductsCSV from '@/components/modals/ProductsCSVModal';

export default {
  name: "Admin",
  components: {
    Search,
    Button,
    Loader,
    Product,
    Pagination,
    ModalProductsCSV
  },
  data() {
    return {
      search: '',
      items: [],
      loading: false,
      paginator: null,
      selectedItem: {}
    }
  },
  watch: {
    search() {
      this.loadItems();
    }
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    ...mapMutations(['openCSVModal', 'closeCSVModal']),

    async loadItems(meta) {
      this.loading = true;
      this.paginator = await paginator('products', meta, this.search);
      this.items = this.paginator.data;
      this.loading = false;
    },
    async sync() {
      this.loading = true;
      await this.loadItems();
    }
  }
};
</script>

<style lang="scss">
.products-csv-button {
  background-color: #7e85b1;
  border-color: #7e85b1;
}

.products-list {
  grid-gap: 35px;
  border-bottom: 1px solid rgba(246, 155, 139, 0.24);
}
</style>
